export const ROLES = {
  admin: {
    label: "Administrador",
    role: "a2470ee4",
  },
  student: {
    label: "Estudiante",
    role: "s5df1sfd54",
  },
  teacher: {
    label: "Profesor",
    role: "zsfd46c25df",
  },
  clinical: {
    label: "Personal de clínica",
    role: "zefd33c03df",
  },
  instructor: {
    label: "Instructor clínico",
    role: "aefd34c04dh",
  },
  rrhh: {
    label: "Recursos Humanos",
    role: "d5dh6sfk54",
  },
  administration: {
    label: "Administración",
    role: "v3dh6slp77",
  },
  administrationFP: {
    label: "Administración FP",
    role: "v3dh6slp44",
  },
};

export const ROLES_RAW = Object.values(ROLES).map((r) => r.role);

export const ROLE_OPTS = Object.values(ROLES).map((r) => ({
  label: r.label,
  value: r.role,
}));