import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  courses,
  coursesForm,
  classrooms,
  classroomsForm,
  subjects,
  subjectsForm,
  bookings,
  bookingsForm,
  grades,
  gradesForm,
  resources,
  resourcesForm,
  studies,
  studiesForm,
  booktypes,
  booktypesForm,
  editions,
  editionsForm,
  editionxsubjects,
  editionxsubjectsForm,
  students,
  studentsForm,
  marks,
  marksForm,
  accesss,
  accesssForm,
  professorRoles,
  professorRolesForm,
  teachers,
  teachersForm,
  subjectTypes,
  subjectTypesForm,
  preenrollments,
  preenrollmentsForm,
  logs,
  logsForm,
  creditTransfers,
  creditTransfersForm,
  creditTransferBlueprints,
  creditTransferBlueprintsForm,
  creditCosts,
  creditCostsForm,
  creditTransferCosts,
  creditTransferCostsForm,
  administrativeCosts,
  administrativeCostsForm,
  applicablediscounts,
  applicablediscountsForm,
  paymentPages,
  paymentPagesForm,
  configurations,
  configurationsForm,
  paymentSheets,
  paymentSheetsForm,
  scholarships,
  scholarshipsForm,
  groups,
  groupsForm,
  permissions,
  permissionsForm,
} from "./Reducers";

export default combineReducers({
  permissions,
  permissionsForm,
  groups,
  groupsForm,
  scholarships,
  scholarshipsForm,
  paymentSheets,
  paymentSheetsForm,
  configurations,
  configurationsForm,
  paymentPages,
  paymentPagesForm,
  applicablediscounts,
  applicablediscountsForm,
  administrativeCosts,
  administrativeCostsForm,
  creditTransferCosts,
  creditTransferCostsForm,
  creditCosts,
  creditCostsForm,
  creditTransferBlueprints,
  creditTransferBlueprintsForm,
  creditTransfers,
  creditTransfersForm,
  logs,
  logsForm,
  preenrollments,
  preenrollmentsForm,
  subjectTypes,
  subjectTypesForm,
  teachers,
  teachersForm,
  professorRoles,
  professorRolesForm,
  accesss,
  accesssForm,
  marks,
  marksForm,
  students,
  studentsForm,
  editionxsubjects,
  editionxsubjectsForm,
  editions,
  editionsForm,
  booktypes,
  booktypesForm,
  studies,
  studiesForm,
  resources,
  resourcesForm,
  grades,
  gradesForm,
  bookings,
  bookingsForm,
  subjects,
  subjectsForm,
  classrooms,
  classroomsForm,
  courses,
  coursesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
