// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  groups: {
    create: `/group`,
    edit: `/group/`,
    delete: `/group/`,
    list: `/group`,
  },
  scholarships: {
    create: `/scholarship`,
    edit: `/scholarship/`,
    delete: `/scholarship/`,
    list: `/scholarship`,
  },
  paymentSheets: {
    create: `/paymentSheet`,
    edit: `/paymentSheet/`,
    delete: `/paymentSheet/`,
    list: `/paymentSheet`,
  },
  configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
  paymentPages: {
    create: `/paymentPage`,
    edit: `/paymentPage/`,
    delete: `/paymentPage/`,
    list: `/paymentPage`,
  },
  applicablediscounts: {
    create: `/applicablediscount`,
    edit: `/applicablediscount/`,
    delete: `/applicablediscount/`,
    list: `/applicablediscount`,
  },
  administrativeCosts: {
    create: `/administrativeCost`,
    edit: `/administrativeCost/`,
    delete: `/administrativeCost/`,
    list: `/administrativeCost`,
  },
  creditTransferCosts: {
    create: `/creditTransferCost`,
    edit: `/creditTransferCost/`,
    delete: `/creditTransferCost/`,
    list: `/creditTransferCost`,
  },
  creditCosts: {
    create: `/creditCost`,
    edit: `/creditCost/`,
    delete: `/creditCost/`,
    list: `/creditCost`,
  },
  creditTransferBlueprints: {
    create: `/creditTransferBlueprint`,
    edit: `/creditTransferBlueprint/`,
    delete: `/creditTransferBlueprint/`,
    list: `/creditTransferBlueprint`,
  },
  creditTransfers: {
    create: `/creditTransfer`,
    edit: `/creditTransfer/`,
    delete: `/creditTransfer/`,
    list: `/creditTransfer`,
  },
  logs: {
    create: `/log`,
    edit: `/log/`,
    delete: `/log/`,
    list: `/log`,
  },
  preenrollments: {
    create: `/preenrollment`,
    edit: `/preenrollment/`,
    delete: `/preenrollment/`,
    list: `/preenrollment`,
  },
  subjectTypes: {
    create: `/subjectType`,
    edit: `/subjectType/`,
    delete: `/subjectType/`,
    list: `/subjectType`,
  },
  teachers: {
    create: `/teacher`,
    edit: `/teacher/`,
    delete: `/teacher/`,
    list: `/teacher`,
  },
  professorRoles: {
    create: `/professorRole`,
    edit: `/professorRole/`,
    delete: `/professorRole/`,
    list: `/professorRole`,
  },
  accesss: {
    create: `/access`,
    edit: `/access/`,
    delete: `/access/`,
    list: `/access`,
  },
  marks: {
    create: `/mark`,
    edit: `/mark/`,
    delete: `/mark/`,
    list: `/mark`,
  },
  students: {
    create: `/student`,
    edit: `/student/`,
    delete: `/student/`,
    list: `/student`,
  },
  editionxsubjects: {
    create: `/editionxsubject`,
    edit: `/editionxsubject/`,
    delete: `/editionxsubject/`,
    list: `/editionxsubject`,
  },
  editions: {
    create: `/edition`,
    edit: `/edition/`,
    delete: `/edition/`,
    list: `/edition`,
  },
  booktypes: {
    create: `/booktype`,
    edit: `/booktype/`,
    delete: `/booktype/`,
    list: `/booktype`,
  },
  studies: {
    create: `/studie`,
    edit: `/studie/`,
    delete: `/studie/`,
    list: `/studie`,
  },
  resources: {
    create: `/resource`,
    edit: `/resource/`,
    delete: `/resource/`,
    list: `/resource`,
  },
  grades: {
    create: `/grade`,
    edit: `/grade/`,
    delete: `/grade/`,
    list: `/grade`,
  },
  bookings: {
    create: `/booking`,
    edit: `/booking/`,
    delete: `/booking/`,
    list: `/booking`,
    notify: `/booking/notify`,
  },
  subjects: {
    create: `/subject`,
    edit: `/subject/`,
    delete: `/subject/`,
    list: `/subject`,
  },
  classrooms: {
    create: `/classroom`,
    edit: `/classroom/`,
    delete: `/classroom/`,
    list: `/classroom`,
  },
  courses: {
    create: `/course`,
    edit: `/course/`,
    delete: `/course/`,
    list: `/course`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
