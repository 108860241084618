import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatGroups = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatScholarships = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatPaymentSheets = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatConfigurations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatPaymentPages = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatApplicablediscounts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.concept}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.concept}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatAdministrativeCosts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.concept}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.concept}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatCreditTransferCosts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.concept}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.concept}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatCreditCosts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.concept} - ${data.type === "COMPLETED" ? "Completa" : "Parcial"}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.concept} - ${data.type === "COMPLETED" ? "Completa" : "Parcial"}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatCreditTransferBlueprints = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatCreditTransfers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatLogs = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatPreenrollments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatSubjectTypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatTeachers = (data, state) => {
  if (!data) {
    return [];
  }

  if (!state.users) {
    return [];
  }

  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.users[data.user] && state.users[data.user].name} ${state.users[data.user] && state.users[data.user].surname} - ${state.users[data.user] && state.users[data.user].document ? state.users[data.user].document.number : "SIN DNI"}`,
      value: data._id,
      dateOfIncorporation: data.dateOfIncorporation && moment(data.dateOfIncorporation),
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.users[data.user] && state.users[data.user].name} ${state.users[data.user] && state.users[data.user].surname} - ${state.users[data.user] && state.users[data.user].document ? state.users[data.user].document.number : "SIN DNI"}`,
      dateOfIncorporation: data.dateOfIncorporation && moment(data.dateOfIncorporation),
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatProfessorRoles = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatAccesss = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatMarks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatStudents = (data, state) => {
  if (!data) {
    return [];
  }
  if (!state.users) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.users[data.user] && state.users[data.user].name} ${state.users[data.user] && state.users[data.user].surname} - ${state.users[data.user] && state.users[data.user].document ? state.users[data.user].document.number : "SIN DNI"}`,
      value: data._id,
      dateOfBirth: data.dateOfBirth && moment(data.dateOfBirth),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.users[data.user] && state.users[data.user].name} ${state.users[data.user] && state.users[data.user].surname} - ${state.users[data.user] && state.users[data.user].document ? state.users[data.user].document.number : "SIN DNI"}`,
      dateOfBirth: data.dateOfBirth && moment(data.dateOfBirth),
    };
  }
};
export const formatEditionxsubjects = (data, getState) => {
  if (!data) {
    return [];
  }
  const state = getState();
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.editions[data.edition] && state.editions[data.edition].label} - ${state.subjects[data.subject] && state.subjects[data.subject].label}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.editions[data.edition] && state.editions[data.edition].label} - ${state.subjects[data.subject] && state.subjects[data.subject].label}`,
    };
  }
};
export const formatEditions = (data, state) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.studies[data.studie] && state.studies[data.studie].name + " " + moment(data.start).format("YY") + "/" + moment(data.end).format("YY")}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.studies[data.studie] && state.studies[data.studie].name + " " + moment(data.start).format("YY") + "/" + moment(data.end).format("YY")}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatBooktypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatStudies = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatResources = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatGrades = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatBookings = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      start: moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatSubjects = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatClassrooms = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatCourses = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname} - ${data.document ? data.document.number : "SIN DNI"}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
